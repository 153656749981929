<template>
  <v-card class="pa-4">
    <!-- dialog alert -->
    <v-dialog
      v-model="dialogAlert"
      width="500"
    >
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>

        <v-card-text>
          {{ alertText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="dialogAlert = false"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog add instance WA -->
    <v-dialog
      v-model="dialogAddInstance"
      width="600px"
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('add') }} {{ $t('channels.channel') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-3"
            @click="closeDialogAddTokped"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="currentTab2 === 1"
            color="success"
            class=""
            @click="saveTokopediaAdd"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <!-- <v-card-text> -->
        <v-tabs
          v-model="currentTab2"
          center-active
        >
          <v-tab
            v-for="(column, index) in columns"
            :key="index + 'columns'"
          >
            {{ column }}
          </v-tab>
        </v-tabs>
        <v-card-text
          v-if="currentTab2 === 3"
          class="mt-6"
        >
          <div class="mx-auto mt-2 text-center">
            <v-btn
              class="mt-2"
              color="primary"
              @click="connectLazadaAccount"
            >
              Connect Your Lazada Seller Account
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text
          v-if="currentTab2 === 2"
          class="mt-6"
        >
          <div class="mx-auto mt-2 text-center">
            <v-btn
              class="mt-2"
              color="primary"
              @click="logInWithFacebook"
            >
              Login Facebook & add Page
            </v-btn>
          </div>
        </v-card-text>
        <v-card-text
          v-if="currentTab2 === 1"
          class="mt-5"
        >
          <v-text-field
            v-model="tokopediaAddPayload.clientSecret"
            outlined
            dense
            :label="`Client Secret`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaAddPayload.clientId"
            outlined
            dense
            :label="`Client ID`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaAddPayload.fs_id"
            outlined
            dense
            :label="`FS ID or Application ID`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaAddPayload.shop_id"
            outlined
            dense
            :label="`Shop ID`"
          ></v-text-field>
        </v-card-text>

        <v-row
          v-if="currentTab2 === 0"
          class="pa-4"
        >
          <v-col cols="auto">
            <div
              v-if="!createLocalInstance.is_adding && createLocalInstance.status != 1"
              class="text-center loading-box"
            >
              <v-progress-circular
                size="128"
                class="mt-15"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </div>
            <div
              v-if="createLocalInstance.is_adding && createLocalInstance.stats != 1 && createLocalInstance.stats != 4"
            >
              <iframe
                id="iframe-qr-code"
                scrolling="no"
                class="loading-iframe"
                title="QR Code"
                width="266"
                height="267"
                style="border: 0"
                :src="getIframeSrc(createLocalInstance.sub_id + '-' + createLocalInstance.instance_id)"
              ></iframe>
            </div>
            <div
              v-if="
                !createLocalInstance.is_adding && (createLocalInstance.status == 1 || createLocalInstance.status == 4)
              "
            >
              <img
                height="260"
                width="284"
                src="@/assets/images/phone_connected_md.png"
              />
            </div>
          </v-col>
          <v-col>
            <span class="font-weight-bold">{{ $t('channels.howToUse') }}</span>
            <br />
            <span v-html="howToUseOne"></span>
            <br />
            <span v-html="howToUseTwo"></span>
            <br />
            <span v-html="howToUseThree"></span>
            <br />
            <span v-html="howToUseFour"></span>
            <br />
            <span v-html="howToUseFive"></span>
            <br />
            <span v-html="howToUseSix"></span>

            <div class="text-center mt-16">
              <v-chip
                v-if="createLocalInstance.status == 0"
                color="error"
              >
                {{ $t('channels.notConnected') }}
              </v-chip>
              <v-chip
                v-if="createLocalInstance.status == 1"
                color="success"
              >
                {{ $t('channels.connected') }}
              </v-chip>
              <v-chip
                v-if="createLocalInstance.status == 2"
                color="secondary"
              >
                {{ $t('channels.disconnected') }}
              </v-chip>
              <v-chip
                v-if="createLocalInstance.status == 3"
                color="secondary"
              >
                {{ $t('channels.disconnected') }}
              </v-chip>
              <v-chip
                v-if="createLocalInstance.status == 4"
                color="warning"
              >
                {{ $t('channels.preparing') }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <!-- </v-card-text> -->
      </v-card>
    </v-dialog>

    <!-- dialog add instance Tokopedia -->
    <v-dialog
      v-model="dialogAddInstanceTokped"
      width="600px"
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('add') }} {{ $t('channels.channel') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="closeDialogAddTokped"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveTokopediaAdd"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field
            v-model="tokopediaAddPayload.clientSecret"
            outlined
            dense
            :label="`Client Secret`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaAddPayload.clientId"
            outlined
            dense
            :label="`Client ID`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaAddPayload.fs_id"
            outlined
            dense
            :label="`FS ID or Application ID`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaAddPayload.shop_id"
            outlined
            dense
            :label="`Shop ID`"
          ></v-text-field>
        </v-card-text>
        <v-divider class="bottom-action" />
        <v-card-actions class="bottom-action text-end justify-end">
          <v-btn
            color="error"
            class="mr-3"
            @click="closeDialogAddTokped"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class=""
            @click="saveTokopediaAdd"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog edit instance Tokopedia -->
    <v-dialog
      v-model="dialogEditInstanceTokped"
      width="600px"
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('edit') }} {{ $t('channels.channel') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="closeDialogEditTokped"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveTokopediaEdit"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field
            v-model="tokopediaEditPayload.label"
            outlined
            dense
            :label="`Shop Name`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaEditPayload.clientSecret"
            outlined
            dense
            :label="`Client Secret`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaEditPayload.clientId"
            outlined
            dense
            :label="`Client ID`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaEditPayload.fs_id"
            outlined
            dense
            :label="`FS ID or Application ID`"
          ></v-text-field>
          <v-text-field
            v-model="tokopediaEditPayload.shop_id"
            outlined
            dense
            :label="`Shop ID`"
          ></v-text-field>
          <span>{{ $t('channels.color') }}</span>
          <v-color-picker
            v-model="tokopediaEditPayload.color"
            dot-size="25"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
          ></v-color-picker>
        </v-card-text>
        <v-divider class="bottom-action" />
        <v-card-actions class="bottom-action text-end justify-end">
          <v-btn
            color="error"
            class="mr-3"
            @click="closeDialogEditTokped"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class=""
            @click="saveTokopediaEdit"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog edit instance WA -->
    <v-dialog
      v-model="dialogEditInstances"
      width="600px"
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('edit') }} {{ $t('channels.channel') }}
          </h3>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="dialogEditInstances = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="saveInstancesEdit"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field
            v-model="instanceName"
            outlined
            dense
            :label="$t('channels.label')"
          ></v-text-field>
          <span>{{ $t('channels.color') }}</span>
          <v-color-picker
            v-model="instanceColor"
            dot-size="25"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
          ></v-color-picker>

          <!-- <v-switch
            v-model="instanceSyncContact"
            :label="$t('channels.syncContact')"
          ></v-switch>
          <v-row align="center" class="px-4">
            <v-checkbox v-model="instanceSettingSync" :label="$t('channels.backgroundMessage')"></v-checkbox>
            <v-btn icon plain small class="ml-1" @click="showInfoSettingSync = !showInfoSettingSync">
              <v-icon>{{ icons.mdiInformation }}</v-icon>
            </v-btn>
          </v-row>
          <span v-if="showInfoSettingSync">{{ $t('channels.infoBackgroundMessage') }}</span> -->
        </v-card-text>
        <v-divider class="bottom-action" />
        <v-card-actions class="bottom-action text-end justify-end">
          <v-btn
            color="error"
            class="mr-3"
            @click="dialogEditInstances = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class=""
            @click="saveInstancesEdit"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog delete instance WA -->
    <v-dialog
      v-model="dialogDeleteInstance"
      width="600px"
    >
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('warning') }}
          </h3>

          <v-spacer></v-spacer>

          <v-btn
            color="error"
            class="mr-3 hide-buton"
            @click="dialogDeleteInstance = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class="hide-buton"
            @click="deleteInstances(idDeleteInstance)"
          >
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          {{ $t('deleteMessage') }}
          <!-- <v-switch
            v-model="instanceSyncContact"
            :label="$t('channels.syncContact')"
          ></v-switch>
          <v-row align="center" class="px-4">
            <v-checkbox v-model="instanceSettingSync" :label="$t('channels.backgroundMessage')"></v-checkbox>
            <v-btn icon plain small class="ml-1" @click="showInfoSettingSync = !showInfoSettingSync">
              <v-icon>{{ icons.mdiInformation }}</v-icon>
            </v-btn>
          </v-row>
          <span v-if="showInfoSettingSync">{{ $t('channels.infoBackgroundMessage') }}</span> -->
        </v-card-text>
        <v-divider class="bottom-action">
        </v-divider>
        <v-card-actions class="justify-end text-end bottom-action">
          <v-btn
            color="error"
            class="mr-3"
            @click="dialogDeleteInstance = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="success"
            class=""
            @click="deleteInstances(idDeleteInstance)"
          >
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="mb-1">
      <v-col
        cols="12"
        md="4"
      >
        <!-- <v-switch
          v-model="muteNotification"
          :disabled="muteNotificationSetting === null"
          @change="changeValueMute"
        >
          <template v-slot:label>
            {{ $t('channels.muteNotification') }}
            <v-progress-circular
              v-if="switchMute"
              :indeterminate="switchMute"
              :value="0"
              size="24"
              class="ml-2"
            ></v-progress-circular>
          </template>
        </v-switch> -->
        <!-- <v-switch
          v-model="boldUnreplied"
          :disabled="boldUnrepliedSetting === null"
          @change="changeValueBold"
        >
          <template v-slot:label>
            {{ $t('channels.boldUnreplied') }}
            <v-progress-circular
              v-if="switchBold"
              :indeterminate="switchBold"
              :value="0"
              size="24"
              class="ml-2"
            ></v-progress-circular>
          </template>
        </v-switch> -->
        <!-- <v-select
          v-if="syncSetting"
          v-model="syncSetting.value"
          item-text="label"
          item-value="value"
          :items="syncSettingOptions"
          dense
          outlined
          @input="
            value => {
              updateSyncSetting(value);
            }
          "
        ></v-select> -->
      </v-col>
      <v-spacer></v-spacer>
      <v-col
        cols="12"
        md="auto"
      >
        <v-tooltip
          v-if="!user.limited_channel"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              class="mr-6"
              v-on="on"
              @click="openDialogAddInstance"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('add')} Channel` }}</span>
        </v-tooltip>
        <!-- <v-tooltip
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="success"
              v-bind="attrs"
              class="mr-6"
              v-on="on"
              @click="dialogAddInstanceTokped = true"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('add')} Tokopedia Shop` }}</span>
        </v-tooltip> -->
        <!-- <v-tooltip
          v-if="user.role === 'Admin'"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="openDialogWorkspaces"
            >
              <v-icon>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('workspaces.editHeader') }}</span>
        </v-tooltip> -->
      </v-col>
      <!-- <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-model="activeWorkspace"
          dense
          outlined
          :items="dataWorkspaces"
          item-text="workspace_name"
          label="Workspace"
          return-object
          :no-data-text="$t('noResults')"
          @change="changeWorkspace(activeWorkspace)"
        />
      </v-col> -->
    </v-row>
    <v-tabs
      v-model="currentTab"
      center-active
    >
      <v-tab
        v-for="(column, index) in columns"
        :key="index + 'columns'"
      >
        {{ column }}
      </v-tab>
    </v-tabs>

    <v-data-table
      :headers="columnsInstances"
      :items="listInstancesFilter"
      :expanded.sync="expanded"
      item-key="_id"
      :loading="loadingTable"
      loading-text="Loading... Please wait"
      :show-expand="currentTab === 0 ? true : false"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
      }"
    >
      <!-- <template slot="no-data">
        {{ $t('noDataLabel') }}
      </template> -->
      <template slot="no-data">
        <v-row
          v-if="currentTab === 1"
          align="center"
          class="py-16 px-4 padding-hero"
        >
          <v-col
            class="text-center"
            lg="6"
            md="6"
            sm="12"
            cols="12"
          >
            <div class="text-start hero-landing">
              <h1 class="hero">
                {{ $t('channels.noDataTitle') }}
              </h1>
              <div class="hero-subtitle hide-lottie">
                <span class="text--primary">{{ $t('channels.noDataSub') }}</span>
              </div>
              <div class="justify-center try-btn mt-3">
                <v-btn
                  elevation="2"
                  class="button-goLogin"
                  color="primary"
                  @click="openDialogAddInstance"
                >
                  <span style="color: white"> {{ $t('add') }}</span>

                  <v-icon color="white">
                    {{ icons.mdiChevronRight }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col
            lg="6"
            md="6"
            sm="12"
            cols="12"
            class="hide-lottie"
          >
            <div
              class="mx-auto"
              style="width: 50%"
            >
              <lottie-player
                src="https://assets9.lottiefiles.com/packages/lf20_vgyx8n4i.json"
                background="transparent"
                speed="1"
                class="mx-auto"
                loop
                autoplay
              ></lottie-player>
            </div>
          </v-col>
        </v-row>
        <div v-if="currentTab === 0">
          {{ $t('noDataLabel') }}
        </div>
      </template>
      <template #[`item.status`]="{ item }">
        <template>
          <v-chip
            v-if="item.status == 0"
            color="error"
          >
            {{ $t('channels.notConnected') }}
          </v-chip>
          <v-chip
            v-if="item.status == 1"
            color="success"
          >
            {{ $t('channels.connected') }}
          </v-chip>
          <v-chip
            v-if="item.status == 2"
            color="secondary"
          >
            {{ $t('channels.disconnected') }}
          </v-chip>
          <v-chip
            v-if="item.status == 3"
            color="secondary"
          >
            {{ $t('channels.disconnected') }}
          </v-chip>
          <v-chip
            v-if="item.status == 4"
            color="warning"
          >
            {{ $t('channels.preparing') }}
          </v-chip>
        </template>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="user.role === 'Admin' && !item.hasOwnProperty('clientSecret')"
              icon
              small
              v-bind="attrs"
              class="cursor-pointer me-2"
              v-on="on"
              @click="openDisconnected(item)"
            >
              <v-icon>{{ icons.mdiLanDisconnect }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('channels.disconnectChannel') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              :disabled="user.limited_channel"
              icon
              small
              v-bind="attrs"
              class="cursor-pointer me-2"
              v-on="on"
              @click="openDialogEditInstances(item)"
            >
              <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('edit')} ${$t('channels.channel')}` }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-if="
                (listInstancesFilter && listInstancesFilter.length > 1) ||
                  item.hasOwnProperty('clientSecret') ||
                  item.hasOwnProperty('id_page')
              "
              :disabled="user.limited_channel"
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="openDialogDeleteInstance(item)"
            >
              <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('delete')} ${$t('channels.channel')}` }}</span>
        </v-tooltip>
      </template>
      <template #[`item.label`]="{ item }">
        <template v-if="overTwoMinutes(item.is_loading)">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
        <template v-else>
          <div
            v-if="item.color"
            class="state-circle d-inline-block"
            :style="{ 'background-color': item.color }"
          />
          <span>{{
            item.label.startsWith('-') || item.label.includes('Default') || item.label.includes('62')
              ? item.label_server
              : item.label
          }}</span>
        </template>
      </template>
      <template #[`item.label_server`]="{ item }">
        <template v-if="overTwoMinutes(item.is_loading)">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
        <template v-else>
          <div
            v-if="item.color"
            class="state-circle d-inline-block"
            :style="{ 'background-color': item.color }"
          />
          <span>{{
            item.label.startsWith('-') || item.label.includes('Default') || item.label.includes('62')
              ? item.label_server
              : item.label
          }}</span>
        </template>
      </template>
      <template #[`item.sync_contact`]="{ item }">
        <v-switch
          v-model="item.sync_contact"
          @change="updateSyncContact"
        ></v-switch>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="pa-4">
            <v-col cols="auto">
              <div v-if="item.status != 1">
                <iframe
                  id="iframe-qr-code"
                  title="QR Code"
                  scrolling="no"
                  width="266"
                  height="267"
                  style="border: 0"
                  class="loading-iframe"
                  :src="getIframeSrc(item.sub_id + '-' + item.instance_id)"
                ></iframe>
              </div>
              <div v-else>
                <img
                  height="260"
                  width="284"
                  src="@/assets/images/phone_connected_md.png"
                />
              </div>
            </v-col>
            <v-col v-if="item.status !== 1">
              <span class="font-weight-bold">{{ $t('channels.howToUse') }}</span>
              <br />
              <span v-html="howToUseOne"></span>
              <br />
              <span v-html="howToUseTwo"></span>
              <br />
              <span v-html="howToUseThree"></span>
              <br />
              <span v-html="howToUseFour"></span>
              <br />
              <span v-html="howToUseFive"></span>
              <br />
              <span v-html="howToUseSix"></span>
            </v-col>
            <v-col v-else>
              <span class="font-weight-bold">{{ $t('channels.howToUseConnect') }}</span>
              <br /><br />
              <span v-html="howToUseOneConnect1"></span>
              <v-btn
                to="inbox"
                plain
                dense
                color="primary"
                small
                no-padding
                class="font-weight-bold"
              >
                Inbox
              </v-btn>
              <span v-html="howToUseOneConnect2"></span>
              <br /><br />
              <span v-html="howToUseTwoConnect1"></span>
              <v-btn
                to="broadcast-list"
                plain
                dense
                color="primary"
                small
                no-padding
                class="font-weight-bold"
              >
                Broadcast
              </v-btn>
              <span v-html="howToUseTwoConnect2"></span>
              <br /><br />
              <span v-html="howToUseThreeConnect1"></span>
              <v-btn
                to="message-template"
                plain
                dense
                color="primary"
                small
                no-padding
                class="font-weight-bold"
              >
                Message Template
              </v-btn>
              <span v-html="howToUseThreeConnect2"></span>
              <br /><br />
              <span v-html="howToUseFourConnect1"></span>
              <v-btn
                to="/operators/replies-history"
                plain
                dense
                color="primary"
                small
                no-padding
                class="font-weight-bold"
              >
                History
              </v-btn>
              <span v-html="howToUseFourConnect2"></span>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>

    <!-- dialog select label -->
    <dialogAddListContact ref="dialogAddListContact"></dialogAddListContact>
    <!-- dialog confirmation -->
    <confirm ref="confirm"></confirm>
  </v-card>
</template>

<script>
/* eslint-disable */

// import built in
import { mdiDeleteOutline, mdiInformation, mdiLanDisconnect, mdiPencilOutline, mdiPlus } from '@mdi/js'
import { RealtimeClient } from '@supabase/realtime-js'
import PouchDB from 'pouchdb-browser'
import channelMixin from '../mixins/channelMixin'
import firestoreDbMixin from '../mixins/firestoreDbMixin'
import pouchDbMixin from '../mixins/pouchDbMixin'
// import custom
import confirm from './component/Confirm.vue'
import dialogAddListContact from './component/DialogAddListContact.vue'

export default {
  name: 'Channels',
  mixins: [pouchDbMixin, channelMixin, firestoreDbMixin],
  components: {
    dialogAddListContact,
    confirm,
  },
  data() {
    return {
      instances: {
        value: [],
      },
      getIframeSrcCount: 0,
      currentTab: 0,
      currentTab2: 0,
      columns: ['WhatsApp', 'Tokopedia', 'Facebook', 'Lazada'],
      //columns: ['WhatsApp'],
      test: null,
      // template
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
        mdiInformation,
        mdiLanDisconnect,
      },
      // pouchdb
      dbSettings: null,
      dbRemoteSettings: null,
      replicationSettings: null,
      // workspaces
      activeWorkspace: null,
      dialogEditWorkspaces: false,
      workspaceName: '',
      // instances
      dialogEditInstances: false,
      dialogDeleteInstance: false,
      dialogAddInstance: false,
      idDeleteInstance: null,
      isNewModeInstances: false,
      instanceName: '',
      instanceColor: '',
      instanceSyncContact: true,
      instanceSettingSync: false,
      selectedInstance: null,
      expanded: [],
      openInstance: null,
      loading: false,
      // instances: null,
      createLocalInstance: {
        is_adding: false,
      },
      // sync setting
      syncSetting: null,
      showInfoSettingSync: false,

      //switch setting
      muteNotificationSetting: null,
      boldUnrepliedSetting: null,
      muteNotification: false,
      boldUnreplied: false,
      switchMute: false,
      switchBold: false,

      dialogAlert: false,
      alertText: '',

      dbSettingsWatcher: null,
      loadingTable: false,

      //! tokopedia
      dialogAddInstanceTokped: false,
      dialogEditInstanceTokped: false,
      tokopediaEditPayload: {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
        color: '',
        label: '',
      },
      tokopediaAddPayload: {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
      },
      allPageData: [],
    }
  },
  async mounted() {
    const REALTIME_URL = 'wss://realtime.superwa.io/socket'
    const client = new RealtimeClient(REALTIME_URL)
    client.connect()
    client.onOpen(() => console.log('Socket opened.'))
    client.onClose(() => console.log('Socket closed.'))
    client.onError(e => console.log('Socket error', e))

    const databaseChanges = client.channel(`realtime:public:${this.user.sub_id}_settings`)
    databaseChanges.on('UPDATE', e => this.updateSetting(e))
    databaseChanges.subscribe()

    // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
    //   sub_id: this.user.sub_id,
    //   token: this.user.token,
    //   key: 'instances',
    // })

    // if (instancePg.status) {
    //   this.instances = instancePg.data
    // }
    const listInstances = await client.records.getFullList('instances', 20, {
      filter: `sub_id = '${this.user.sub_id}'`
    })
        
    if(listInstances && listInstances.length > 0) {
      this.instances.value = listInstances
    }
    // Listen to events on the entire database.
    window.addEventListener('message', this.receiveMessage, false)

    // await this.fdbGetInstances(this.user.sub_id)

    // //console.log(this.listInstances, this.instances, `INI INSTANCE`)

    // this.instances = this.listInstances
    this.loading = true
    await this.checkInstances()
    this.loading = false
    if (this.allSettings.length === 0) {
      this.loadingTable = true
    } else {
      await this.getSettings()
    }
    this.loadingTable = true
    this.switchMute = true
    this.switchBold = true

    await this.$store.dispatch('auth/getChannelAccess', {
      sub_id: this.user.sub_id,
      email: this.user.email,
      token: this.user.token,
    })
    // await this.connectSettings()

    if (this.instances) {
      if (this.instances.value) {
        this.instances.value.forEach(instance => {
          if (instance.status != 1) {
            this.expandInstances(instance)
          }
        })
      }
      // else {
      //   const items = await this.mixinLoadSettingsRemote()
      //   const instancesSetting = items.find(x => x.key === 'instances')
      //   this.$store.commit('auth/setListInstance', instancesSetting)
      //   instancesSetting.value.forEach(instance => {
      //     if (instance.status == 0) {
      //       this.expandInstances(instance)
      //     }
      //   })
      //   // this.instances = this.listInstances
      // }
    }

    await this.$store.dispatch('auth/fetchUserWorkspace', {
      email: this.user.email,
    })

    this.dataWorkspaces.forEach(el => {
      if (el.sub_id === this.user.sub_id) {
        this.activeWorkspace = el
      }
    })

    this.loadingTable = false
    this.switchMute = false
    this.switchBold = false
    // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, payload => {
    //   if (payload.doc._id === 'instances' && this.instances) {
    //     if (this.instances.value.length === payload.doc.value.length) {
    //       for (var instance of this.instances.value) {
    //         const obj = payload.doc.value.find(x => x.instance_id === instance._id)
    //         if (obj) {
    //           if (instance.label === '-') {
    //             instance.label = obj.label_server
    //           }
    //           instance.is_loading = obj.is_loading
    //         }
    //       }
    //       this.$store.commit('auth/setListInstance', this.instances)
    //     }
    //   }
    //   // this.$store.dispatch('global/setSettingsById', payload)
    // })
    await this.loadFacebookSDK(document, 'script', 'facebook-jssdk')
    await this.initFacebook()
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
    if (this.dbSettingsWatcher !== null) this.dbSettingsWatcher.cancel()
    window.removeEventListener('message', this.receiveMessage, false)
  },
  methods: {
    async updateSetting(data) {
      if (data && data.record && data.record.key == 'instances') {
        if (data.record.value) {
          data.record.value = JSON.parse(data.record.value)
        }
        //console.log(data.record,'channelsmd2')
        // this.instances = data.record
      }
    },
    // async findListPage(userId) {

    // },

    // Lazada Processes Start
    async connectLazadaAccount() {
      // //console.log(this.$router.params, 'Params dari hasil redirect')

      // temporary credential
      const { VUE_APP_LAZADA_APP_KEY, VUE_APP_LAZADA_REDIRECT_URL } = process.env
      const client_id = VUE_APP_LAZADA_APP_KEY
      const redirect_uri = VUE_APP_LAZADA_REDIRECT_URL
      //console.log('Temporary app key :', client_id)
      //console.log('Temporary app redirect URL :', redirect_uri)
      const query = {
        response_type: 'code',
        force_auth: true,
        redirect_uri,
        client_id,
      }
      // auth link
      // https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://be-tokopedia.deploy.cbs.co.id/superwa/couchdb/lazada/get-seller&client_id=108130
      // //console.log(this.$router.currentRoute)
      // const slug = `https://auth.lazada.com/oauth/authorize?response_type=${query.response_type}&force_auth=${
      //   query.response_type
      // }&redirect_uri=${`http://localhost:8080/channels`}&client_id=${query.client_id}`
      const slug = `https://auth.lazada.com/oauth/authorize?response_type=${query.response_type}&force_auth=${query.response_type}&redirect_uri=${query.redirect_uri}&client_id=${query.client_id}`
      window.open(slug, '_blank')
    },
    // Lazada Processes End

    async logOutWithFacebook() {
      // await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      // await this.initFacebook();
      await window.FB.logout(function (response) {
        //console.log(response, 'ini log out')
        if (response) {
          alert('You are logged in &amp; cookie set!')
          // Now you can redirect the user or do an AJAX request to
          // a PHP script that grabs the signed request from the cookie.
        } else {
          alert('User cancelled login or did not fully authorize.')
        }
      })
      return false
    },
    async logInWithFacebook() {
      const self = this
      await window.FB.login(
        async function (response) {
          //console.log(response, 'ini log in')
          if (response.authResponse) {
            let fbAuthRes = response
            await FB.api(`/${response.authResponse.userID}/accounts`, async function (res) {
              //console.log(res, 'list page')
              if (res && !res.error) {
                /* handle the result */
                self.allPageData = res.data
                let instance = self.instances
                let facebookPage = []
                var subscribeAppFields = [
                  'messages',
                  'messaging_optins',
                  'messaging_postbacks',
                  'messaging_handovers',
                  'standby',
                  'message_deliveries',
                  'message_reads',
                  'message_echoes',
                ]
                // self.$store.commit('auth/setListInstance', self.allPageData)
                self.allPageData.forEach(async (page, index) => {
                  await FB.api(
                    '/' + page.id + '/subscribed_apps',
                    'POST',
                    {
                      subscribed_fields: subscribeAppFields,
                      access_token: page.access_token,
                    },
                    async function (subres) {
                      if (subres.success) {
                        let color = Math.floor(Math.random() * 16777215).toString(16)

                        while (color.length < 6) {
                          color = Math.floor(Math.random() * 16777215).toString(16)
                        }
                        var findIndex = instance.value.findIndex(function (item) {
                          return item.phone_number == page.id
                        })

                        let instance_id
                        if (findIndex === -1) {
                          instance_id = self.$nanoid().toLowerCase()
                        } else {
                          instance_id = instance.value[findIndex]._id
                          instance.value.splice(findIndex, 1)
                        }
                        const addInstancePayload = {
                          _id: 'facebook' + instance_id,
                          instance_id: 'facebook' + instance_id,
                          sub_id: self.user.sub_id,
                          label: page.name,
                          color: '#' + color,
                          sync_contact: false,
                          setting_sync: false,
                          phone_number: page.id,
                          status: 1,
                          is_loading: 0,
                          label_server: page.name,
                          page_name: page.name,
                          id_page: page.id,
                          // access_token: page.access_token,
                        }
                        const addFacebookPage = {
                          instance: instance_id,
                          id: page.id,
                          access_token: page.access_token,
                        }
                        facebookPage.push(addFacebookPage)

                        // if (findIndex !== -1) {
                        //   await self.$store.dispatch('auth/removerFacebookPage', {
                        //     sub_id: self.user.sub_id,
                        //     id: instance.value[findIndex].id_page,
                        //     instance: instance.value[findIndex].instance,
                        //   })
                        //   instance.value.splice(findIndex, 1)
                        // }
                        instance.value.push(addInstancePayload)

                        self.$store.commit('auth/setListInstance', instance)

                        //console.log(self.allPageData.length, 'aaa-psklajsklan', index)
                        // this.$store.commit('auth/setListInstance', self.instances)
                        if (self.allPageData.length - 1 == index) {
                          // //console.log('allinaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa')
                          await self.$store.dispatch('auth/registerFacebookPage', {
                            sub_id: self.user.sub_id,
                            page: facebookPage,
                            userID: fbAuthRes.authResponse.userID,
                          })

                          await self.$store.dispatch('global/updateSettingsByKey', {
                            sub_id: self.user.sub_id,
                            token: self.user.token,
                            key: 'instances',
                            value: instance.value,
                          })
                          // await self.updateInstances()
                        }
                      }
                    },
                  )
                })
              }
            })
          } else {
            //console.log('err Login')
          }
        },
        {
          scope: 'pages_messaging,pages_read_engagement,pages_manage_metadata,pages_show_list',
        },
      )
      // //console.log(all)
      //pages_read_engagement pages_read_user_content pages_show_list
      return false
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '1144002486333804', //You will need to change this
          autoLogAppEvents: true,
          xfbml: true,
          cookie: true, // This is important, it's not enabled by default
          version: 'v13.0',
        })
      }
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    },

    openDialogEditTokopediaInstance(data) {
      this.dialogEditInstanceTokped = true
      this.tokopediaEditPayload = JSON.parse(JSON.stringify(data))
    },
    closeDialogEditTokped() {
      this.dialogAddInstance = false
      this.dialogEditInstanceTokped = false
      this.tokopediaEditPayload = {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
        color: '',
        label: '',
      }
    },
    async saveTokopediaEdit() {
      const updateData = JSON.parse(JSON.stringify(this.tokopediaEditPayload))
      if (!updateData.color) {
        updateData.color = '#' + Math.floor(Math.random() * 16777215).toString(16)
      }
      const payloadUpdate = {
        sub_id: this.user.sub_id,
        clientSecret: updateData.clientSecret,
        clientId: updateData.clientId,
        fs_id: updateData.fs_id,
        shop_id: updateData.shop_id,
        _id: `shop-${this.user.sub_id}-${updateData.shop_id}`,
      }
      const res = await this.$store.dispatch('auth/updateTokopediaShop', payloadUpdate)

      if (res.status) {
        const fetchRoom = await this.$store.dispatch('auth/getTokopediaRoom', payloadUpdate)
        if (fetchRoom.status) {
          var index = this.instances.value.findIndex(function (item) {
            return item.instance_id == updateData._id
          })

          if (index !== -1) {
            this.loadingTable = true
            this.instances.value.splice(index, 1, this.tokopediaEditPayload)
            this.$store.commit('auth/setListInstance', this.instances)
            // }
            this.dialogEditInstanceTokped = false
            this.dialogEditInstance = false
            this.closeDialogAddTokped()
            await this.updateInstances(updateData._id)
          }
        }
      }
    },
    closeDialogAddTokped() {
      this.dialogAddInstance = false
      this.dialogAddInstanceTokped = false
      this.tokopediaAddPayload = {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
      }
    },
    async saveFacebookPage(page_name, id_page, access_token) {
      let color = Math.floor(Math.random() * 16777215).toString(16)

      while (color.length < 6) {
        color = Math.floor(Math.random() * 16777215).toString(16)
      }
      // this.tokopediaAddPayload.sub_id = this.user.sub_id

      // this.loadingTable = true
      // this.dialogAddInstanceTokped = false
      // this.dialogAddInstance = false
      // //console.log(this.tokopediaAddPayload, `INI PAYLOAD`)
      // const res = await this.$store.dispatch('auth/registerTokopediaShop', this.tokopediaAddPayload)
      // //console.log(res, `INI RES`)
      // if (res.status) {

      await this.updateInstances()
      // const addPayload = { ...this.tokopediaAddPayload, instance_id }
      // await this.$store.dispatch('auth/getTokopediaRoom', addPayload)
      // }
      // this.loadingTable = false
      // this.tokopediaAddPayload = {
      //   clientSecret: '',
      //   clientId: '',
      //   fs_id: '',
      //   shop_id: '',
      // }
    },
    async saveTokopediaAdd() {
      let color = Math.floor(Math.random() * 16777215).toString(16)

      while (color.length < 6) {
        color = Math.floor(Math.random() * 16777215).toString(16)
      }
      this.tokopediaAddPayload.sub_id = this.user.sub_id

      this.loadingTable = true
      this.dialogAddInstanceTokped = false
      this.dialogAddInstance = false
      //console.log(this.tokopediaAddPayload, `INI PAYLOAD`)
      const res = await this.$store.dispatch('auth/registerTokopediaShop', this.tokopediaAddPayload)
      //console.log(res, `INI RES`)
      if (res.status) {
        const instance_id = this.$nanoid().toLowerCase()
        const addInstancePayload = {
          _id: instance_id,
          instance_id,
          sub_id: this.user.sub_id,
          label: res?.data?.shop_name,
          color: '#' + color,
          sync_contact: true,
          setting_sync: false,
          phone_number: res?.data?.shop_id,
          status: 1,
          is_loading: 0,
          label_server: res?.data?.shop_name,
          shop_name: res?.data?.shop_name,
          clientSecret: this.tokopediaAddPayload.clientSecret,
          clientId: this.tokopediaAddPayload.clientId,
          fs_id: this.tokopediaAddPayload.fs_id,
          shop_id: res?.data?.shop_id,
        }

        this.instances.value.push(addInstancePayload)
        this.$store.commit('auth/setListInstance', this.instances)
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
          value: this.instances.value,
        })
        const addPayload = { ...this.tokopediaAddPayload, instance_id }
        await this.$store.dispatch('auth/getTokopediaRoom', addPayload)
      }
      this.loadingTable = false
      this.tokopediaAddPayload = {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
      }
    },
    async checkInstances() {
      // if (this.instancesSetting) {
      let isChange = false
      if (this.instances) {
        for (var data of this.instances.value) {
          if (data.phone_number && !data.hasOwnProperty('clientSecret') && !data.hasOwnProperty('id_page')) {
            const isReady = await this.$store.dispatch('auth/checkChannelReady', data.sub_id + '-' + data.instance_id)
            if (!isReady) {
              await this.$store.dispatch('auth/checkStatusMd', {
                id: data.sub_id + '-' + data.instance_id,
                ready: isReady,
              })

              data.status = this.$store.getters['auth/getStatusInstance']
              isChange = true
            } else {
              await this.$store.dispatch('auth/checkStatusMd', {
                id: data.sub_id + '-' + data.instance_id,
                ready: isReady,
              })
              //console.log('check status channel', data.sub_id + '-' + data.instance_id)
              if (!data.label || data.label == '-') {
                data.label = data.label_server
                  ? data.label_server
                  : this.$store.getters['auth/getInstanceName']
                  ? this.$store.getters['auth/getInstanceName']
                  : data.phone_number
                isChange = true
              }
              // if (!data.status || data.status !== this.$store.getters['auth/getStatusInstance']) {
              data.status = this.$store.getters['auth/getStatusInstance']
              isChange = true
              // }
            }
            // this.syncContactsWa(data)
          }
        }

        if (
          this.instanceValues &&
          this.instanceValues.value.filter(x => (x.status === 0 || x.status === 3) && x.phone_number).length > 0
        ) {
          this.$store.dispatch('global/setStatusChannels', true)
        } else {
          this.$store.dispatch('global/setStatusChannels', false)
          this.$store.commit('inbox/setBannerAlertText', ``)
        }
      }
      if (isChange) {
        // //console.log(this.instanceValues)
        this.instances.value.forEach(async x => {
          if (
            x.status === 3 &&
            this.instances.value.length >= this.getIframeSrcCount &&
            !x.hasOwnProperty('clientSecret') &&
            !x.hasOwnProperty('id_page')
          ) {
            // await this.getIframeSrc(x.sub_id + '-' + x.instance_id)
            this.getIframeSrcCount += 1
          }
        })
        // this.instances = this.instanceValues
        // this.$store.commit('auth/setListInstance', this.instances)
      }
      // }
    },
    openDisconnected(channel) {
      this.$refs.confirm
        .open(
          this.$t('delete'),
          `${this.$t('disconnectedConfirmation')} ${channel.label} ?`,
          { color: 'red' },
          this.$t('delete'),
        )
        .then(async agree => {
          if (agree) {
            this.loading = true
            try {
              await this.$store.dispatch('auth/deleteInstance', this.user.sub_id + '-' + channel.instance_id)
              await this.$store.dispatch('rooms/deleteChannels', {
                token: this.user.token,
                instance_id: channel.instance_id,
                sub_id: this.user.sub_id,
              })
            } catch (e) {
              //console.log(e)
            }

            try {
              await this.$store.commit('auth/setIsResetChannels', true)
              await this.$store.dispatch('rooms/resetChannels', {
                token: this.user.token,
                instance_id: channel.instance_id,
                sub_id: this.user.sub_id,
              })
              window.location.reload()
              // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
              //   sub_id: this.user.sub_id,
              //   token: this.user.token,
              //   key: 'instances',
              // })

              // if (instancePg.status) {
              //   this.instances = instancePg.data
              // }
              // await this.checkInstances()
            } catch (err) {
              console.log(err)
            }
            this.loading = false
          }
        })
    },
    overTwoMinutes(loading) {
      if (loading === false) return false
      if (loading === true) return true
      if (loading === 0) return false
      return !(this.$moment() > this.$moment(loading).add(20, 'seconds'))
    },
    async changeValueMute() {
      this.switchMute = true
      const temp = this.muteNotificationSetting.value
      this.muteNotificationSetting.value = this.muteNotification
      await this.updateSettings(this.muteNotificationSetting, async x => {
        if (x === false) {
          this.muteNotification = !this.muteNotification
        }
        this.$store.dispatch('global/setMuteValue', this.muteNotification)
        this.switchMute = false
      })
    },
    async changeValueBold() {
      this.switchBold = true
      this.boldUnrepliedSetting.value = this.boldUnreplied
      await this.updateSettings(this.boldUnrepliedSetting, async x => {
        if (x === false) {
          this.boldUnreplied = !this.boldUnreplied
        }
        this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
        this.switchBold = false
      })
    },
    openDialogDeleteInstance(item) {
      this.dialogDeleteInstance = true
      this.idDeleteInstance = item
    },

    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
    async changeWorkspace(data) {
      if (data.sub_id != this.user.sub_id) {
        await this.$store.commit('auth/setUserObj', {
          key: 'sub_id',
          payload: data.sub_id,
        })

        await this.$store.commit('auth/setUserObj', {
          key: 'token',
          payload: data.token,
        })

        await this.$store.commit('auth/setUserObj', {
          key: 'role',
          payload: data.role,
        })

        await new PouchDB('db_message_templates').destroy()
        await new PouchDB('db_settings').destroy()
        await new PouchDB('db_contacts').destroy()
        await new PouchDB('db_rooms').destroy()
        await new PouchDB('db_instances').destroy()
        await new PouchDB('db_broadcasts').destroy()
        await new PouchDB('db_history').destroy()
        await new PouchDB('db_chat').destroy()

        // REMOVE VUEX STORE
        await this.$store.dispatch('global/setContacts', [])
        await this.$store.dispatch('global/setSettings', [])
        await this.$store.dispatch('global/setRooms', null)
        await this.$store.dispatch('global/setFilterChat', null)
        await this.$store.dispatch('global/setLastSeq', 0)
        await this.$store.dispatch('global/setStatusChannels', false)
        this.$store.commit('auth/setListInstance', [])
        await this.checkReplicateContacts(true)

        if (this.instances) {
          if (!this.instances.value) {
            this.instances.value = []
          }

          this.instances.value.forEach(instance => {
            if (instance.status == 0) {
              this.expandInstances(instance)
            }
          })
        }

        this.dataWorkspaces.forEach(el => {
          if (el.sub_id === this.user.sub_id) {
            this.activeWorkspace = el
          }
        })

        await this.$store.dispatch('auth/getChannelAccess', {
          sub_id: this.user.sub_id,
          email: this.user.email,
          token: this.user.token,
        })

        setTimeout(function () {
          window.location.reload()
        }, 1000)
      }

      this.$router.go()
    },
    async getSettings() {
      // if (fromServer) {
      const items = await this.loadSettings()
      var res = await this.mappingSettingChannels(items)

      if (this.instances) {
        // //console.log(this.getGlobalChannel, `INI GLOBAL CHANNEL`)
        // //console.log(`INI INSTANCE`, res.instances)
        // this.instances = res.instances
        // this.$store.commit('auth/setListInstance', res.instances)
        if (this.getGlobalChannel) {
          const currentChannel = this.instances.value.find(
            el => el.instance_id === this.getGlobalChannel.value.instance_id,
          )
          if (currentChannel) {
            this.$store.commit('global/setGlobalChannel', {
              label:
                currentChannel.label && currentChannel.label === '-'
                  ? currentChannel.label_server
                  : currentChannel.label,
              value: currentChannel,
            })
          }
        }
      }

      if (res.syncSetting) {
        this.syncSetting = res.syncSetting
      }

      if (res.muteNotificationSetting) {
        this.muteNotificationSetting = res.muteNotificationSetting
        this.muteNotification = this.muteNotificationSetting.value
        this.$store.dispatch('global/setMuteValue', this.muteNotification)
      } else {
        await this.updateSettings(
          { _id: 'mute_notification', key: 'mute_notification', value: this.muteNotification },
          async x => {
            this.muteNotificationSetting = await this.loadSettingsById(x.id)
            this.muteNotification = this.muteNotificationSetting.value
            this.$store.dispatch('global/setMuteValue', this.muteNotification)
          },
        )
      }

      if (res.boldUnrepliedSetting) {
        this.boldUnrepliedSetting = res.boldUnrepliedSetting
        this.boldUnreplied = this.boldUnrepliedSetting.value
        this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
      } else {
        await this.updateSettings(
          { _id: 'bold_unreplied', key: 'bold_unreplied', value: this.boldUnreplied },
          async x => {
            this.boldUnrepliedSetting = await this.loadSettingsById(x.id)
            this.boldUnreplied = this.boldUnrepliedSetting.value
            this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
          },
        )
      }
    },

    async deleteInstances(instance) {
      var self = this
      if (instance.hasOwnProperty('clientSecret')) {
        const payloadDelete = {
          sub_id: this.user.sub_id,
          id: `shop-${this.user.sub_id}-${instance.shop_id}`,
          shop_id: instance.shop_id.toString(),
        }
        const res = await this.$store.dispatch('auth/deleteTokopediaShop', payloadDelete)
        // if (res.status) {
        var index = this.instances.value.findIndex(function (item) {
          return item.instance_id == instance._id
        })

        // if (index == -1) {
        //   index = 0
        // }

        if (index !== -1) {
          this.instances.value.splice(index, 1)
          // this.$store.commit('auth/setListInstance', this.instances)
          // }

          await self.updateInstances(instance._id)
        }
      } else if (instance.hasOwnProperty('id_page')) {
        var subscribeAppFields = [
          'messages',
          'messaging_optins',
          'messaging_postbacks',
          'messaging_handovers',
          'standby',
          'message_deliveries',
          'message_reads',
          'message_echoes',
        ]
        // all.$store.commit('auth/setListInstance', all.allPageData)

        await FB.api(
          '/' + instance.id_page + '/subscribed_apps',
          'DELETE',
          {
            subscribed_fields: subscribeAppFields,
            access_token: instance.access_token,
          },
          function (subres) {
            // //console.log('masuk sini')
            if (subres.success) {
              //console.log(subres)
            }
          },
        )

        var index = this.instances.value.findIndex(function (item) {
          return item.instance_id == instance._id
        })

        // if (index == -1) {
        //   index = 0
        // }

        if (index !== -1) {
          await self.$store.dispatch('auth/removerFacebookPage', {
            sub_id: this.user.sub_id,
            id: instance.value[index].id_page,
            instance: instance.value[index].instance,
          })
          this.instances.value.splice(index, 1)
          // this.$store.commit('auth/setListInstance', this.instances)
          // }

          await this.updateInstances(instance._id)
        }
      } else {
        var index = this.instances.value.findIndex(function (item) {
          return item.instance_id == instance._id
        })

        // if (index == -1) {
        //   index = 0
        // }

        if (index !== -1) {
          this.instances.value.splice(index, 1)
          // this.$store.commit('auth/setListInstance', this.instances)
        }

        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
          value: this.instances.value,
        })
        await this.$store.dispatch('rooms/deleteChannels', {
          token: this.user.token,
          instance_id: instance._id,
          sub_id: this.user.sub_id,
        })
        // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'instances',
        // })

        // if (instancePg.status) {
        //   this.instances = instancePg.data
        // }
        const listInstances = await client.records.getFullList('instances', 20, {
          filter: `sub_id = '${this.user.sub_id}'`
        })
            
        if(listInstances && listInstances.length > 0) {
          this.instances.value = listInstances
        }
        // await self.updateInstances(instance._id)
      }

      // var idxFirestore = this.listInstances.value.findIndex(item => {
      //   return item._id == id
      // })
      // if (idxFirestore > -1) {
      //   this.listInstances.value.splice(idxFirestore, 1)
      // }
      // const res = await this.$store.dispatch('firestore/editSetting', {
      //   sub_id: this.user.sub_id,
      //   field: 'instances',
      //   value: this.listInstances.value,
      // })
      // if (res) {
      //   this.$store.commit('auth/setListInstance', this.listInstances)
      // }
      this.dialogDeleteInstance = false
    },
    async updateInstances(id = null) {
      await this.updateSettings(this.instances, async success => {
        this.loadingTable = true
        await this.getSettings(true)
        this.loadingTable = false
        // this.$store.commit('auth/setListInstance', this.instances.value)
        if (id !== null) {
          // await this.$store.dispatch('auth/deleteChannelMd', this.user.sub_id + '-' + id)
          await this.$store.dispatch('auth/deleteInstance', this.user.sub_id + '-' + id)
        }
      })
    },
    openDialogEditInstances(data) {
      if (data.hasOwnProperty('clientSecret')) {
        this.openDialogEditTokopediaInstance(data)
      } else {
        this.isNewModeInstances = false
        this.instanceName = data.label && data.label !== '-' ? data.label : data.label_server
        this.instanceColor = data.color
        this.instanceSyncContact = data.sync_contact
        this.instanceSettingSync = data.setting_sync ? data.setting_sync : false
        this.selectedInstance = data
        this.dialogEditInstances = true
      }
    },
    async openDialogAddInstance() {
      this.dialogAddInstance = true
      await this.createInstances()
    },
    async createInstances() {
      const instancesId = this.$nanoid().toLowerCase()
      let limitOps = await this.$store.dispatch('subscriptions/getSubscriptionInfo', {
        activeWorkspace: this.activeWorkspace,
        email: this.user.email,
      })
      // //console.log(limitOps, 'aaaaaaaaaaaaaaaaaaaaaaaaaaa')
      let color = Math.floor(Math.random() * 16777215).toString(16)

      while (color.length < 6) {
        color = Math.floor(Math.random() * 16777215).toString(16)
      }

      if (this.instances.value.length < limitOps.data.data.dataValues.max_channel) {
        this.isNewModeInstances = true
        this.instanceName = '-'
        this.instanceSyncContact = true
        this.instanceSettingSync = false
        this.createLocalInstance = {
          is_adding: true,
          _id: instancesId,
          instance_id: instancesId,
          sub_id: this.user.sub_id,
          label: this.instanceName,
          color: '#' + color,
          sync_contact: this.instanceSyncContact,
          setting_sync: this.instanceSettingSync,
          phone_number: '',
          status: 0,
          is_loading: true,
        }
        this.openInstance = this.createLocalInstance
      } else {
        this.showAlert(this.$t('warningmaxchannel'))
      }
    },
    async saveInstancesEdit() {
      if (this.instanceName && this.instances) {
        this.dialogEditInstances = false
        this.dialogAddInstance = false

        this.loading = true
        this.selectedInstance.label = this.instanceName
        this.selectedInstance.color = this.instanceColor

        if (!this.selectedInstance.color) {
          this.selectedInstance.color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        }

        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
          value: this.instances.value,
        })
        // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'instances',
        // })

        // if (instancePg.status) {
        //   this.instances = instancePg.data
        // }
        const listInstances = await client.records.getFullList('instances', 20, {
          filter: `sub_id = '${this.user.sub_id}'`
        })
            
        if(listInstances && listInstances.length > 0) {
          this.instances.value = listInstances
        }
        // await this.updateInstances()
        // const res = await this.$store.dispatch('firestore/editSetting', {
        //   sub_id: this.user.sub_id,
        //   field: 'instances',
        //   value: this.instances.value,
        // })
        // if (res) {
        //   this.$store.commit('auth/setListInstance', this.instances)
        // }

        this.loading = false
      }
    },
    async saveInstances() {
      this.loadingTable = true
      if (this.isNewModeInstances) {
        if (this.instances) {
          this.instances.value.push(this.createLocalInstance)
          this.$store.commit('auth/setListInstance', this.instances)

          if (this.instances.value[this.instances.value.length - 1]) {
            this.expandInstances(this.instances.value[this.instances.value.length - 1])
          }

          await this.updateInstances()
          await this.$store.dispatch('global/updateSettingsByKey', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            key: 'instances',
            value: this.instances.value,
          })

          this.dialogEditInstances = false
          this.dialogAddInstance = false
        }
      } else {
        if (this.instanceName) {
          this.selectedInstance.label = this.instanceName
          this.selectedInstance.color = this.instanceColor
          this.selectedInstance.sync_contact = this.instanceSyncContact
          this.selectedInstance.setting_sync = this.instanceSettingSync

          if (!this.selectedInstance.color) {
            this.selectedInstance.color = '#' + Math.floor(Math.random() * 16777215).toString(16)
          }

          await this.updateInstances()
          await this.$store.dispatch('global/updateSettingsByKey', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            key: 'instances',
            value: this.instances.value,
          })

          this.dialogEditInstances = false
          this.dialogAddInstance = false
        }
      }
      this.loadingTable = false
    },
    openDialogWorkspaces() {
      this.workspaceName = this.getWorkspaceName
      this.$refs.dialogAddListContact.open(this.workspaceName).then(async dialog => {
        if (dialog.status) {
          this.workspaceName = dialog.name
          await this.saveWorkspaces()
        }
      })
    },
    async saveWorkspaces() {
      var res = await this.$store.dispatch('auth/editUser', {
        email: this.user.email,
        sub_id: this.user.sub_id,
        workspace_name: this.workspaceName,
      })

      if (res.status) {
        await this.$store.dispatch('auth/fetchUserWorkspace', {
          email: this.user.email,
        })

        this.dataWorkspaces.forEach(el => {
          if (el._id === this.activeWorkspace._id) {
            this.activeWorkspace = el
            this.activeWorkspace.workspace_name = this.workspaceName
          }
        })
        // this.$store.commit('auth/setSelectedWorkspace', this.getWorkspaceName)
      } else {
        this.$q.dialog({
          title: 'Oops...',
          message: res.errorMessage,
        })
      }

      this.dialogEditWorkspaces = false
    },
    async updateSyncSetting(value) {
      const self = this
      self.syncSetting.value = value
      await this.updateSettings(this.syncSetting)
    },
    async updateSyncContact() {
      await this.updateInstances()
    },
    async receiveMessage(event) {
      const self = this
      // //console.log(event, '======================= event')
      if (
        event.origin === process.env.VUE_APP_WA_SERVICES_URL.substr(0, process.env.VUE_APP_WA_SERVICES_URL.length - 1)
      ) {
        if (event.data.err) {
          //console.log(event.data.err)
        } else {
          this.$store.commit('global/setLoadingMessages', true)
          // //console.log(true, `MASUK ELSE 1`)
          if (event.data.id.split('-').length > 1) {
            const subId = event.data.id.split('-')[0]
            const instanceId = event.data.id.split('-')[1]
            let existPhoneNumber, obj

            if (this.createLocalInstance.is_adding) {
              this.createLocalInstance.phone_number = event.data.no
              existPhoneNumber = self.instances.value.find(instance => {
                return instance.phone_number === event.data.no
              })
              delete this.createLocalInstance.is_adding
              obj = this.createLocalInstance
            } else {
              existPhoneNumber = self.instances.value.find(instance => {
                return instance.phone_number === event.data.no
              })

              obj = self.instances.value.find(instance => {
                return instance.sub_id === subId && instanceId === instance._id
              })
            }

            if (existPhoneNumber && obj && existPhoneNumber.instance_id !== obj.instance_id) {
              this.showAlert('Phone number already in Channel')
              // await this.$store.dispatch('auth/deleteChannelMd', this.user.sub_id + '-' + obj.instance_id)
              await this.$store.dispatch('auth/deleteInstance', this.user.sub_id + '-' + obj.instance_id)
              this.dialogAddInstance = false
              return
            } else {
              if (obj) {
                // await self.$store.dispatch('auth/checkStatus', subId + '-' + instanceId)

                obj.is_loading = this.$moment().unix()

                if (!obj.label || obj.label == '-') {
                  obj.label = obj.label
                    ? obj.label
                    : obj.label_server
                    ? obj.label_server
                    : self.$store.getters['auth/getInstanceName']
                    ? self.$store.getters['auth/getInstanceName']
                    : event.data.name
                }

                obj.status = 1 //self.$store.getters['auth/getStatusInstance']
                obj.phone_number = event.data.no
                obj.is_loading = this.$moment().unix()

                // self.listInstances.value.push(obj)
                // await self.updateInstances()
              }

              if (this.createLocalInstance.status == 1) {
                await this.saveInstances()
              }

              // update firestore
              // const res = await this.$store.dispatch('firestore/editSetting', {
              //   sub_id: this.user.sub_id,
              //   field: 'instances',
              //   value: this.listInstances.value,
              // })
              // if (res) {
              //   this.$store.commit('auth/setListInstance', this.listInstances)
              // }

              // reset channel
              // await self.$store.dispatch('auth/resetStatus', subId + '-' + instanceId)

              // fix security chat
              var endpoint = process.env.VUE_APP_DB_API_URL + 'superwa/couchdb/update-database-security'

              var security = {
                members: {
                  names: [self.user.sub_id],
                  roles: ['_admin'],
                },
                admins: {
                  roles: ['_admin'],
                },
              }

              var body = {
                subId: self.user.sub_id,
                security: security,
              }

              await self.$axiosLimit.post(endpoint, body)
            }
          }
        }
      }
    },
    getIframeSrc(data) {
      //console.log(process.env.VUE_APP_WA_SERVICES_URL + data, 'data iframe')
      return process.env.VUE_APP_WA_SERVICES_URL + 'register-channel-pg/' + data
    },
    expandInstances(data) {
      if (
        this.expanded.find(item => {
          return item === data._id
        })
      ) {
        this.openInstance = null

        var index = this.expanded.findIndex(function (item) {
          return item.id == data._id
        })

        if (index == -1) {
          index = 0
        }

        if (index !== -1) {
          this.expanded.splice(index, 1)
        }
      } else {
        this.openInstance = data
        this.expanded.push(data)
      }
    },
  },
  computed: {
    getGlobalChannel() {
      return this.$store.getters['global/getGlobalChannel']
    },
    instanceValues() {
      const instances = this.instances

      if (instances) {
        if (!instances.value) {
          instances.value = []
        }
      }

      return instances
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    columnsInstances() {
      if (this.currentTab === 0) {
        return [
          { text: this.$t('channels.label'), value: 'label' },
          { text: `${this.$t('channels.phoneNumber')}`, value: 'phone_number' },
          { text: 'Status', value: 'status' },
          // { text: 'Sync Contact', value: 'sync_contact' },
          { text: this.$t('channels.actions'), value: 'actions' },
        ]
      } else {
        return [
          { text: this.$t('channels.label'), value: 'label' },
          { text: `Shop ID`, value: 'phone_number' },
          { text: 'Status', value: 'status' },
          // { text: 'Sync Contact', value: 'sync_contact' },
          { text: this.$t('channels.actions'), value: 'actions' },
        ]
      }
    },
    // instances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   if (inst) {
    //     return inst
    //   } else {
    //     return []
    //   }
    // },
    listInstancesFilter() {
      if (this.instances) {
        if (this.instances.value) {
          if (this.currentTab === 0) {
            return this.instances.value.filter(x => {
              if (this.user.limited_channel) {
                return this.user.enabled_channel && this.user.enabled_channel.find(y => y === x._id)
              } else if (
                !this.user.limited_channel &&
                !x.hasOwnProperty('clientSecret') &&
                !x.hasOwnProperty('id_page')
              ) {
                return true
              }
              return false
            })
          } else if (this.currentTab === 1) {
            return this.instances.value.filter(x => {
              if (x.hasOwnProperty('clientSecret')) {
                return true
              }
              return false
            })
          } else if (this.currentTab === 2) {
            return this.instances.value.filter(x => {
              if (x.hasOwnProperty('id_page')) {
                return true
              }
              return false
            })
          }
        }
      }

      return []
    },
    workspaces() {
      return this.$store.getters['auth/getListWorkspaces']
    },
    getWorkspaceName() {
      var data = this.workspaces.find(workspace => workspace.sub_id === this.user.sub_id)

      if (data) {
        return data.workspace_name ? data.workspace_name : 'Workspace ' + data.sub_id
      }

      return ''
    },
    selectedWorkspace() {
      return this.$store.getters['auth/getSelectedWorkspace']
    },
    dataWorkspaces() {
      return this.$store.getters['auth/getListWorkspaces']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    syncSettingOptions() {
      return [
        {
          label: this.$t('channels.syncSettingOptionOne'),
          value: 0,
        },
        {
          label: this.$t('channels.syncSettingOptionTwo'),
          value: 1,
        },
      ]
    },
    howToUseOne() {
      return this.$t('channels.howToUseOne')
    },
    howToUseTwo() {
      return this.$t('channels.howToUseTwo')
    },
    howToUseThree() {
      return this.$t('channels.howToUseThree')
    },
    howToUseFour() {
      return this.$t('channels.howToUseFour')
    },
    howToUseFive() {
      return this.$t('channels.howToUseFive')
    },
    howToUseSix() {
      return this.$t('channels.howToUseSix')
    },
    howToUseOneConnect1() {
      return this.$t('channels.howToUseOneConnect1')
    },
    howToUseTwoConnect1() {
      return this.$t('channels.howToUseTwoConnect1')
    },
    howToUseThreeConnect1() {
      return this.$t('channels.howToUseThreeConnect1')
    },
    howToUseFourConnect1() {
      return this.$t('channels.howToUseFourConnect1')
    },
    howToUseOneConnect2() {
      return this.$t('channels.howToUseOneConnect2')
    },
    howToUseTwoConnect2() {
      return this.$t('channels.howToUseTwoConnect2')
    },
    howToUseThreeConnect2() {
      return this.$t('channels.howToUseThreeConnect2')
    },
    howToUseFourConnect2() {
      return this.$t('channels.howToUseFourConnect2')
    },
  },
}
</script>

<style scoped>
.theme--dark.v-card > .v-card__text,
.theme--dark.v-card > .v-card__subtitle {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.bottom-action {
  display: none;
}

.loading-iframe {
  /* background: url(https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif) center center no-repeat; */
}

.loading-box {
  width: 276px;
  height: 277px;
}

@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
}

.state-circle {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 6px;
  transition: 0.3s;
}

.state-label {
  display: inline-block;
}

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .bottom-action {
    display: inherit;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}
</style>
